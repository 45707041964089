import moment from "moment";

export function checkPhone(value){
    let phoneExp = /^\d+$/; // 电话正则匹配
    let spaceExp = /^\s*$/; // 空格正则匹配
    // let checkPhone = (rule, value, callback) => {
    //     if (spaceExp.test(value)) {
    //         callback(new Error("请输入手机号"));
    //     } else if (!phoneExp.test(value)) {
    //         callback(new Error("请输入正确的手机号"));
    //     } else {
    //         callback();
    //     }
    // };
    return (!spaceExp.test(value)) && phoneExp.test(value);
}
export function  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
export function transYearMonth(list){
    for(let i in list){
        if(list[i].startDate.indexOf('年')===-1){
            list[i].startDate = moment(list[i].startDate).format("YYYY年M月");
            list[i].endDate = moment(list[i].endDate).format("YYYY年M月");
        }
    }
    return list;
}
export function jsCopy(o){
    return JSON.parse(JSON.stringify(o));
}

export function getParentIdArr(subId,allCat){
    let idArr = [];
    function inner(subId,allCat){
        let subCat = {};
        allCat.forEach(v => {
            if(v.id === subId){
                subCat = v;
            }
        })
        if(subCat.parentId === "0"){
            idArr.push(subCat.id);
        }else{
            idArr.push(subCat.id);
            allCat.forEach(v => {
                if(v.id === subCat.parentId){
                    let subArr = inner(v.id,allCat);
                    idArr.concat(subArr);
                }
            })
        }
    }
    inner(subId,allCat)
    return idArr.reverse();
}

export function keywordSplit(keyword){
    keyword = keyword.replaceAll(/(，|,|;|；)/g," ");
    let newKeyword = [];
    keyword.split(" ").forEach((v)=>{
        if(v.trim()!==""){
            newKeyword.push(v.trim());
        }
    })
    return newKeyword;
}
